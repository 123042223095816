import React, { useState } from "react";
import DataContext from "./DataContext";

const LocalObject = typeof window !== "undefined" && window;
// global state management
const getLocalItems = () => {
  let accountInformation =
    LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("acc_info")
      : "";
  if (accountInformation !== undefined) {
    return LocalObject.localStorage !== undefined
      ? JSON.parse(LocalObject.localStorage.getItem("acc_info"))
      : "";
  } else {
    return "";
  }
};
const handleTokenValue = () => {
  var tokenValue =
    LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("access_token")
      : "";
  if (tokenValue !== undefined) {
    return LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("access_token")
      : "";
  } else {
    return "";
  }
};

export const DataProvider = ({ children }) => {
  // sidebar component
  const [stepsData, setStepsData] = useState({});
  const [transtalteFromInput, setTranslateFromInput] = useState(
    "Select source language"
  );
  const [transtalteToInput, setTranslateToInput] = useState(
    "Select target language"
  );
  const [fileValue, setfileValue] = useState([]);
  const [numberOfWords, setNumberOfWords] = useState(0);
  const [storeValue, setStoreValue] = useState([]);
  const [transtalteServiceInput, setTranstalteServiceInput] = useState({
    category_name: "Document / Text translation",
    category_id: 1,
  });
  const [transtalteSubjectInput, setTranstalteSubjectInput] = useState({
    subcategory_name: "General Business / Commerce",
    subcategory_id: 1,
  });
  const [translateMarketingSourceInput, setTranslateMarketingSourceInput] =
    useState(1);

  const [quoteId, setQuoteId] = useState(0);

  const [token, setToken] = useState(handleTokenValue());
  const [allData, setAllData] = useState(null);
  const [accountInfo, setAccountInfo] = useState(getLocalItems());
  const [thirdStepData, setThirdStepData] = useState({});
  const [userEmail, setUserMail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [toggleOrder, settoggleOrder] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});

  return (
    <DataContext.Provider
      value={{
        stepsData,
        setStepsData,
        transtalteFromInput,
        setTranslateFromInput,
        transtalteToInput,
        setTranslateToInput,
        fileValue,
        setfileValue,
        paymentInfo,
        setPaymentInfo,
        numberOfWords,
        setNumberOfWords,
        storeValue,
        token,
        accountInfo,
        setAccountInfo,
        setToken,
        setStoreValue,
        toggleOrder,
        settoggleOrder,
        transtalteServiceInput,
        setTranstalteServiceInput,
        transtalteSubjectInput,
        setTranstalteSubjectInput,
        quoteId,
        setQuoteId,
        thirdStepData,
        setThirdStepData,
        LocalObject,
        allData,
        setAllData,
        setUserName,
        userName,
        userEmail,
        setUserMail,
        translateMarketingSourceInput,
        setTranslateMarketingSourceInput,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
